<template>
  <div class="about">
    <div :class="[$route.name == 'Home' ? 'hidden' : '']">
      <TopSection />
    </div>
    <section
      class="bg-gray-900 dark:bg-gray-100 py-8"
      :class="[
        $route.name == 'Home'
          ? 'bg-gray-900 py-8 border-t-2 border-yellow-700 dark:border-yellow-600'
          : '',
      ]"
    >
      <div class="container py-8 px-6 mx-auto ">
        
        <a
          class="
            tracking-wide
            no-underline
            hover:no-underline
            font-bold
            text-yellow-700
            dark:text-yellow-600
            text-2xl
            mb-8
          "
          href="#"
        >
          Hakkımızda
        </a>
        <p class="my-8 text-gray-400 dark:text-gray-800">
Kurucumuz Kemal SERİNDAĞ Sektördeki faliyetlerine 1950 yılında başlayıp, 1961 Yılında Masif İş Olarak İlk Mobilya Atolyesini Kurmuştur. 
Yaklaşık 30  Yıl Sektöre hizmet veren firmamız 1991 Yılında Önder Möble Olarak değiştirilmiştir. Bu isim Konulurken Lider ve Yol Gösteren anlamlarınada gelen önder kelimesi tercih edilmiştir.
2020 Yılında ise  Yaklaşık 70 yıllık birikimimiz ve kalitemizle sadece müşterilerimizin değil, sektörün de takdirini kazanmış bulunuyoruz. 
Firmamız MGS Mobilya olarak yola aynı heyecan ve Özveriyle devam etme kararı almıştır. 
        </p>        <a
          class="
            tracking-wide
            no-underline
            hover:no-underline
            font-bold
            text-yellow-700
            dark:text-yellow-600
            text-2xl
            mb-8
          "
          href="#"
        >
          Kalite Politikamız
        </a>
                <p class="my-8 text-gray-400 dark:text-gray-800">
Mobilya imalat, tadilat, Yapı ve dekorasyon sektöründe göstermiş olduğumuz mesleki faaliyetlerimizde 1.sınıf işçilik, kaliteli malzeme ve garantili aksesuar alışkanlığımızdan 
hiç vazgeçmediğimizi ve bundan sonra da hiç vazgeçmeyeceğimizi içtenlikle Söyleyebiliriz.
        </p>
      </div>
    </section>
    <!--
        <Team />
        -->
  </div>
</template>
<style scoped>

</style>
<script>
import TopSection from "@/components/TopSection";
//import Team from "@/components/share/Team";
export default {
  name: "About",
  components: {
    TopSection,
    //Team,
  },
};
</script>
